// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `div.container-assignment-center {
    text-align: left;
    margin: 24px;
    margin-right: 10%;
    margin-top: 0;
}

div.container-clients-caregivers {
    background-color: #B9BA96;
    height: 800px;
    margin-top: 20px;
}

table {
    width: 100%;
}

table.tbl-clients-caregivers {
    td {
        padding: 24px;
    }

    td.client-info {
        padding-left: 48px;
    }

    th {
        text-align: center;
        font-size: 2.4rem;
    }
    
    tr.tbl-header {
        border-bottom: 20px solid #B9BA96;
        border-top: 20px solid #B9BA96;
    }

    tr.tbl-rows {
        background-color: #D9D9D9;
        border-bottom: 20px solid #B9BA96;
        border-left: 40px solid #B9BA96;
        border-right: 40px solid #B9BA96;
    }
}`, "",{"version":3,"sources":["webpack://./src/AssignmentCenterPage.css"],"names":[],"mappings":"AAAA;IACI,gBAAgB;IAChB,YAAY;IACZ,iBAAiB;IACjB,aAAa;AACjB;;AAEA;IACI,yBAAyB;IACzB,aAAa;IACb,gBAAgB;AACpB;;AAEA;IACI,WAAW;AACf;;AAEA;IACI;QACI,aAAa;IACjB;;IAEA;QACI,kBAAkB;IACtB;;IAEA;QACI,kBAAkB;QAClB,iBAAiB;IACrB;;IAEA;QACI,iCAAiC;QACjC,8BAA8B;IAClC;;IAEA;QACI,yBAAyB;QACzB,iCAAiC;QACjC,+BAA+B;QAC/B,gCAAgC;IACpC;AACJ","sourcesContent":["div.container-assignment-center {\n    text-align: left;\n    margin: 24px;\n    margin-right: 10%;\n    margin-top: 0;\n}\n\ndiv.container-clients-caregivers {\n    background-color: #B9BA96;\n    height: 800px;\n    margin-top: 20px;\n}\n\ntable {\n    width: 100%;\n}\n\ntable.tbl-clients-caregivers {\n    td {\n        padding: 24px;\n    }\n\n    td.client-info {\n        padding-left: 48px;\n    }\n\n    th {\n        text-align: center;\n        font-size: 2.4rem;\n    }\n    \n    tr.tbl-header {\n        border-bottom: 20px solid #B9BA96;\n        border-top: 20px solid #B9BA96;\n    }\n\n    tr.tbl-rows {\n        background-color: #D9D9D9;\n        border-bottom: 20px solid #B9BA96;\n        border-left: 40px solid #B9BA96;\n        border-right: 40px solid #B9BA96;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
