import React, { useState, useEffect, useRef, useContext } from "react";
import {
  useParams,
  Navigate,
  useNavigate,
  useLocation,
} from "react-router-dom";
import { useAuth0, withAuthenticationRequired } from "@auth0/auth0-react";
import EventModal from "./EventModal";
import { useCaregiver } from "./CaregiverContext";
import { ClientContext } from "./ClientContext";
import "./ClientCalendar.css";
import Calendar from "@event-calendar/core";
import TimeGrid from "@event-calendar/time-grid";
import DayGrid from "@event-calendar/day-grid";
import Interaction from "@event-calendar/interaction";
import List from "@event-calendar/list";
import "@event-calendar/core/index.css";
import CompassIcon from "../src/assets/CompassIcon.png";

import Layout from "./Layout";

require("dotenv").config();
const BASE_URL = process.env.REACT_APP_BASE_URL;

const ClientCalendar = () => {
  const { clientId } = useParams();
  const {
    selectedClient,
    setSelectedClient,
    handleClientChange,
    clients,
    setClients,
    fetchClients,
    fetchClientName,
    clientName,
    fetchActivities,
    activities
  } = useContext(ClientContext);
  const [selectedEvent, setSelectedEvent] = useState(null);
  const calendarRef = useRef(null);
  const [modalOpen, setModalOpen] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  const { caregiverId, isCaregiver } = useCaregiver();

  //references selectclienthomepage selectedclient
  useEffect(() => {
    const clientFromState = location.state?.selectedClient || clientId;
    if (clientFromState) {
      setSelectedClient(selectedClient);
      fetchClientName(selectedClient);
      fetchActivities(selectedClient);
    }
  }, [clientId, location.state?.selectedClient]);

  useEffect(() => {
    console.log("is cg?", isCaregiver);
    if (clientId) {
      fetchClientName(clientId);
      fetchActivities(clientId);
    }
  }, [clientId]);

  useEffect(() => {
    // Fetch clients associated with the logged-in caregiver
    if (caregiverId) {
      fetchClients();
    }
  }, []);

  

  const openModal = (event) => {
    // setSelectedTask(task);
    setSelectedEvent(event);
    setModalOpen(true);
  };
  useEffect(() => {
    const ecContainer = document.getElementById("ec");
    if (!ecContainer) return;

    const ec = new Calendar({
      target: ecContainer,
      props: {
        plugins: [DayGrid, TimeGrid, Interaction, List],
        options: {
          view: "dayGridMonth",
          events: activities,
          eventDidMount: function (info) {
            info.el.id = `${info.event.id}`;
            info.el.addEventListener("click", function () {
              handleEventClick({ el: info.el });
            });
            // Apply custom color based on event_state
            if (info.event.extendedProps.event_state === "completed") {
              info.el.style.backgroundColor = "green";
            } else {
              // console.log("info.event", info.event);
              info.el.style.backgroundColor = "red";
            }
          },

          headerToolbar: {
            start: "title prev,next today",
            center: "",
            end: "dayGridMonth,timeGridWeek,timeGridDay,listWeek",
          },
          dateClick: handleDateClick,
          eventClick: handleEventClick,
        },
      },
    });

    return () => ec.destroy();
  }, [activities]);

  const getDefaultDateTime = () => {
    const now = new Date();
    const start = new Date(
      now.getFullYear(),
      now.getMonth(),
      now.getDate(),
      now.getHours(),
      now.getMinutes(),
    );
    const end = new Date(start.getTime() + 60 * 60 * 1000);

    return { start: start.toISOString(), end: end.toISOString() };
  };

  const handleDateClick = (info) => {
    console.log("Empty area clicked!");
    const { start, end } = getDefaultDateTime();
    console.log("start", start, "end", end);
    console.log("dateClick -> info", info);
    const newEvent = {
      id: `temp-${Date.now()}`,
      user_id: "",
      title: "",
      description: "",
      type: "",
      event_state: "incomplete",
      start_time: info.date || start,
      end_time: info.date || end,
      steps: [{ title: "", content: "", sequence: 1 }],
      created_by: 0,
      updated_by: 0,
      created_on: new Date().toISOString(),
      updated_on: new Date().toISOString(),
    };
    setSelectedEvent(newEvent);
    setModalOpen(true);
  };

  const handleEventClick = async (info) => {
    console.log("Event clicked!");
    try {
      const response = await fetch(
        `${BASE_URL}/activities/${info.el.id}`, {
          headers: {
            'Authorization': 'Bearer ' + localStorage.getItem('token'),
          }
      });
      const eventData = await response.json();
      if (!response.ok) {
        throw new Error(eventData.message || "Failed to fetch event details");
      }

      console.log("Event data:", eventData);
      setSelectedEvent(eventData.activity);

      const stepsResponse = await fetch(
        `${BASE_URL}/event_steps/${eventData.activity.id}`, {
          headers: {
            'Authorization': 'Bearer ' + localStorage.getItem('token'),
          }
      });
      const stepsData = await stepsResponse.json();

      console.log("Steps data:", stepsData);

      setSelectedEvent({
        ...eventData.activity,
        steps: stepsData.steps,
      });
      setModalOpen(true);
    } catch (error) {
      console.error("Error fetching event details:", error);
      alert("Failed to fetch event details. Please try again.");
    }
  };

  // const handleClientChange = (clientIdOrEvent) => {
  //   console.log('client changing')
  //   const clientId =
  //     typeof clientIdOrEvent === "string"
  //       ? clientIdOrEvent
  //       : clientIdOrEvent.target.value;
  //   setSelectedClient(clientId);
  //   // fetchContacts(caregiverId, clientId);
  // };

  const closeModal = () => {
    setModalOpen(false);
    setSelectedEvent(null);
    fetchActivities();
  };

  const handleHomepageClick = () => {
    navigate("../select-client-homepage", { state: { caregiverId } });
  };

  return (
    <Layout
      clients={clients}
      selectedClient={selectedClient}
      handleClientChange={handleClientChange}
      // fetchContacts={fetchContacts}
      // handleClientScheduleClick={handleClientScheduleClick}
    >
      <div className="outer-calendar-container">
        <div className="client-calendar-container">
          <div className="calendar-header">
            <h1 className="calendar-header-h1">{`Add or edit ${clientName}'s activities.`}</h1>
            {isCaregiver && (
              <button className="add-activity-button" onClick={handleDateClick}>
                Add new activity
              </button>
            )}
          </div>
          <div id="ec" className="calendar-container"></div>
        </div>
        <EventModal
          isOpen={modalOpen}
          clientId={clientId}
          closeModal={closeModal}
          selectedEvent={selectedEvent}
          isCaregiver={isCaregiver}
        />
      </div>
    </Layout>
  );
};

export default withAuthenticationRequired(ClientCalendar);
