import React, { useState, useEffect, useContext } from "react";
import { useAuth0, withAuthenticationRequired } from "@auth0/auth0-react";
import { useNavigate } from "react-router-dom";
import "./SelectClientHomepage.css";
import homeScreenSplash from "../src/assets/homescreen-splash.jpg";
import LayoutPlain from "./LayoutPlain";

function SelectClientHomepage() {
  const navigate = useNavigate();

  return (
    <LayoutPlain>
      <img
        src={homeScreenSplash}
        alt="Home Screen Splash"
        className="splash-image"
      />
      <h1 className="intro">Welcome to Compass Care</h1>
      {localStorage.getItem("isSuperAdmin") == "true" ||
      localStorage.getItem("isAdmin") == "true" ? (
        <div
          style={{
            padding: "24px 48px",
            backgroundColor: "#D9D9D9",
            margin: "0 auto",
            width: "600px",
          }}
        >
          <h2 style={{ fontWeight: "bold" }}>Assignment Center</h2>
          <div>
            <h5>Manage which clients are assigned to which admins.</h5>
            <br />
            <button
              className="btn-branding-plain"
              style={{
                width: "120px",
                height: "60px",
                backgroundColor: "#A7A466",
              }}
              onClick={() => navigate("/assignment_center")}
            >
              Let's go
            </button>
          </div>
        </div>
      ) : (
        <h1>Where care takes direction.</h1>
      )}
      {/* <h1>Compass Care</h1> */}
      {/* <div className="navigation-buttons"> */}
      {/* <button onClick={fetchContacts}>Client call lists</button> */}
      {/* Uncomment and adjust as needed */}
      {/* <button className='large-button' onClick={() => alert(`Track Client Activities: ${selectedClient}`)}>
          Track client activities
        </button> */}
      {/* </div> */}
    </LayoutPlain>
  );
}

export default withAuthenticationRequired(SelectClientHomepage);
