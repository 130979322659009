import React, { useState, useEffect, useContext } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { withAuthenticationRequired } from "@auth0/auth0-react";
import LayoutPlain from "./LayoutPlain";
import { ClientContext } from "./ClientContext";
import Select from 'react-select';
import "./AddNewAssignmentPage.css";

const BASE_URL = process.env.REACT_APP_BASE_URL;

const AddNewAssignmentPageHeader = ({ children }) => {
  return (
    <div>
      <table>
        <td>
          <h1 style={{ textAlign: "left", fontSize: "2.6rem" }}>
            <strong>Add New Assignment</strong>
          </h1>
          <h5>
            You can assign as many clients to as many caregivers as you need.
            After assigning a client, they’ll be added to that caregiver’s
            remote care.
          </h5>
        </td>
        {children}
      </table>
    </div>
  );
};

const AddNewAssignmentPage = ({ children }) => {
  const navigate = useNavigate();
  const { clients, setClients } = useContext(ClientContext);
  const [didMount, setDidMount] = useState(false);
  const [caregivers, setCaregivers] = useState([]);
  const [clientOptions, setClientOptions] = useState([]);
  const [caregiverOptions, setCaregiverOptions] = useState([]);
  const [selectedClients, setSelectedClients] = useState([]);
  const [selectedCaregivers, setSelectedCaregivers] = useState([]);

  useEffect(() => {
    fetchCaregivers();

    setClientOptions(clients)

    setTimeout(() => {
      setDidMount(true)
    }, 2000);
  }, [didMount]);

  const fetchCaregivers = async () => {
    console.log("fetchCaregivers...");
    let url = `${BASE_URL}/caregivers?user_id=${localStorage.getItem(
      "caregiverId"
    )}`;
    if (localStorage.getItem("isSuperAdmin")) {
      url = `${BASE_URL}/caregivers`;
    }
    try {
      const response = await fetch(url, {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      });
      if (response.ok) {
        const data = await response.json();
        setCaregivers(data);
        setCaregiverOptions(caregivers)
      } else {
        console.error("Failed to fetch caregivers for caregiver");
      }
    } catch (error) {
      console.error("Error fetching caregivers for caregiver:", error);
    }
  };

  const onInputChangeClients = (
    newValue, action
  ) => {
    setSelectedClients(newValue);
  };

  const onInputChangeCaregivers = (
    newValue, action
  ) => {
    setSelectedCaregivers(newValue);
  };

  async function assignCaregiver(caregiverIds, clientId) {
    const params = { caregiverIds: caregiverIds.join(', ') };

    const response = await fetch(`${process.env.REACT_APP_BASE_URL}/clients/${clientId}/assignCaregiver`, {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + localStorage.getItem('token'),
      },
      method: 'POST',
      body: JSON.stringify(params),
    });

    await response.json();
  }

  const handleSetAssignment = () => {
    let isSuccess = false;
    for (let m = 0; m < selectedClients.length; m++) {
      const client = selectedClients[m];
      const caregiverIds = selectedCaregivers.map((a) => a.value)
      try {
        assignCaregiver(caregiverIds, client.value)
        isSuccess = true
      } catch (error) {}
    }
    if (isSuccess) {
      setSelectedClients([])
      setSelectedCaregivers([])
      navigate("/assignment-set-success");
    }
  }

  return !didMount ? (null) : (
    <LayoutPlain>
      <div className="container-assignment-center" style={{ height: '500px' }}>
        <AddNewAssignmentPageHeader />
        <div style={{ display: "flex" }}>
          <div style={{ width: '100px' }}>
            Assign
          </div>
          <div style={{ width: '500px' }}>
            <h2>Clients</h2>
            <Select
              defaultValue={[]}
              isMulti
              name="clients"
              onChange={onInputChangeClients}
              options={clientOptions}
              value={selectedClients}
              className="basic-multi-select"
              classNamePrefix="select" />
          </div>
          <div style={{ width: '100px' }}>
            To
          </div>
          <div style={{ width: '500px' }}>
            <h2>Caregivers</h2>
            <Select
              defaultValue={[]}
              isMulti
              name="caregivers"
              onChange={onInputChangeCaregivers}
              options={caregiverOptions}
              value={selectedCaregivers}
              className="basic-multi-select"
              classNamePrefix="select" />
          </div>
        </div>
        <div style={{ textAlign: "right" }}>
          <button
            onClick={() => {
              navigate("/assignment_center");
            }}
          >
            Cancel
          </button>
          &nbsp;
          <button
            onClick={handleSetAssignment}
          >
            Set Assignment
          </button>
        </div>
      </div>
    </LayoutPlain>
  );
};

export default withAuthenticationRequired(AddNewAssignmentPage);
