import React, { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { withAuthenticationRequired } from "@auth0/auth0-react";
import checkCircularImage from "../src/assets/check_circular.png";
import "./InviteSentPage.css";
import LayoutPlain from "./LayoutPlain";

const InviteSentPage = () => {
    const [didMount, setDidMount] = useState(false);
    const navigate = useNavigate();
    const location = useLocation();

    useEffect(() => {
        if (didMount) {
            return;
        }

        if (location.state && location.state.user) {
            setDidMount(true);
        }
    }, [didMount]);

    return !didMount ? null : (
        <LayoutPlain>
            <div style={{ width: '50%', margin: 'auto' }}>
                <img
                    src={checkCircularImage}
                    alt="Invite Sent"
                    style={{ width: '150px', height: '150px' }}
                />
                <h1 style={{  fontSize: '2rem' }}>Invite Sent!</h1>
                <p style={{ fontSize: '1.2rem' }}>We sent an email to the caregiver inviting them to register their account, and they’ve been added to your caregiver list. After they register, you can assign them to clients.</p>
                <button
                    class="btn-branding"
                    style={{ color: '#000', backgroundColor: '#66A77B', padding: '10px 20px', fontSize: '1.2rem' }}
                    onClick={(ev) => {
                        ev.preventDefault();
                        navigate(`/caregiver_profile/${location.state.user.id}`, { state: { user: location.state.user } });
                    }}
                >
                    Go to caregiver
                </button>
            </div>
        </LayoutPlain>
    );
};

export default withAuthenticationRequired(InviteSentPage);
