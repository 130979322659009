import React, { useEffect, useState, useContext } from "react";
import { useCaregiver } from "./CaregiverContext";
import { ClientContext } from "./ClientContext";
import "./EventModal.css";
require("dotenv").config();
const BASE_URL = process.env.REACT_APP_BASE_URL;

function EventModal({
  isOpen,
  closeModal,
  selectedEvent,
  handleUpdateActivitySteps,
  clientId,
  // isCaregiver,
}) {
  const [editedSteps, setEditedSteps] = useState([]);
  const [showSteps, setShowSteps] = useState(false);
  const [displayStart, setDisplayStart] = useState("");
  const [suretyClicked, setSuretyClicked] = useState(false);
  const [displayEnd, setDisplayEnd] = useState("");
  const [reminderEnabled, setReminderEnabled] = useState(false);
  const [reminderTimes, setReminderTimes] = useState([]);
  const { caregiverId, isCaregiver } = useCaregiver();
  const {
    selectedClient,
    setSelectedClient,
    handleClientChange,
    clients,
    setClients,
    fetchClients,
    fetchClientName,
    clientName,
    fetchActivities,
    activities
  } = useContext(ClientContext);
  const [isComplete, setIsComplete] = useState(false);

  const [formData, setFormData] = useState({
    id: "",
    title: "",
    description: "",
    type: "event",
    event_state: "",
    start_time: "",
    end_time: "",
    steps: [{ title: "", content: "", sequence: 1 }],
    user_id: clientId,
    created_by: 0,
    updated_by: 0,
    created_on: new Date().toISOString(),
    updated_on: new Date().toISOString(),
  });
  // console.log("client id", clientId);
  const formatDateTimeLocal = (isoString) => {
    if (!isoString) return "";

    const localDate = new Date(isoString);
    const offset = localDate.getTimezoneOffset() * 60000; // offsets in milliseconds
    const adjustedDate = new Date(localDate.getTime() - offset);

    return adjustedDate.toISOString().slice(0, 16);
  };

  useEffect(() => {
    if (isOpen && selectedEvent) {
      setReminderEnabled(selectedEvent.reminder_enabled || false);
      setReminderTimes(selectedEvent.reminder_times || []);
      setIsComplete(selectedEvent.event_state === "completed");
    }
  }, [isOpen, selectedEvent]);

  useEffect(() => {
    // console.log("cgid2", caregiverId);
    // console.log("selectevent", selectedEvent);
    if (caregiverId) {
      setFormData((prevFormData) => ({
        ...prevFormData,
        user_id: caregiverId,
      }));
    }
  }, [caregiverId]);
  // console.log('EM selectedEvent', selectedEvent)
  // console.log('EM FormData', formData)
  useEffect(() => {
    console.log("EM useffect formdata", formData);
    if (isOpen && selectedEvent) {
      console.log("EM selectedEvent", selectedEvent);
      setDisplayStart(formatDateTimeLocal(selectedEvent.start_time));
      setDisplayEnd(formatDateTimeLocal(selectedEvent.end_time));
      setFormData({
        id: selectedEvent.id || "",
        title: selectedEvent.title || "",
        description: selectedEvent.description || "",
        type: "event",
        event_state: selectedEvent.event_state || "",
        start_time: formatDateTimeLocal(selectedEvent.start_time),
        end_time: formatDateTimeLocal(selectedEvent.end_time),
        steps: selectedEvent.steps || [{ title: "", content: "", sequence: 1 }],
        user_id: clientId,
        // user_id: caregiverId,
        created_by: selectedEvent.created_by || 0,
        updated_by: selectedEvent.updated_by || 0,
        created_on: selectedEvent.created_on || new Date().toISOString(),
        updated_on: selectedEvent.updated_on || new Date().toISOString(),
      });
      if (selectedEvent.steps) {
        setEditedSteps(selectedEvent.steps);
      }
    }
  }, [isOpen, selectedEvent]);

  useEffect(() => {
    document.addEventListener("keydown", handleEscapeKeyPress);
    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("keydown", handleEscapeKeyPress);
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [isOpen]);

  const fetchActivityById = async (formDataId) => {
    console.log("formdataid", formDataId);
      fetch(`${BASE_URL}/activities/${formDataId}`, {
          headers: {
            'Authorization': 'Bearer ' + localStorage.getItem('token'),
          }
      })
      .then((response) => response.json())
      .then((data) => {
        if (data.success) {
          console.log("Activity data:", data.activity);
          // Process and display the activity data in your UI
        } else {
          console.error("Activity not found");
        }
      })
      .catch((error) => {
        console.error("Error fetching activity:", error);
      });
  };

  const handleEscapeKeyPress = (event) => {
    if (event.keyCode === 27 && isOpen) {
      setShowSteps(false);
      setSuretyClicked(false);
      closeModal();
    }
  };

  const handleClickOutside = (event) => {
    if (isOpen && !event.target.closest(".modal")) {
      setShowSteps(false);
      setSuretyClicked(false);
      closeModal();
    }
  };

  const handleActivityChange = (field, value) => {
    console.log("handleactivityClick");
    setFormData((prev) => ({
      ...prev,
      [field]: value,
    }));
  };
  const handleChange = (e) => {
    const { name, value } = e.target;
    if (name === "start_time" || name === "end_time") {
      setFormData((prevFormData) => ({
        ...prevFormData,
        type: "event",
        [name]: value,
      }));
    } else {
      setFormData({ ...formData, [name]: value });
    }
  };

  const addStep = () => {
    const sequence = formData.steps.length + 1;
    setFormData({
      ...formData,
      type: "event",
      steps: [...formData.steps, { title: "", content: "", sequence }],
    });
  };

  const removeStep = async (index) => {
    const stepId = formData.steps[index].id; // Use the correct step id

    if (stepId) {
      try {
        const response = await fetch(
          `${BASE_URL}/event_steps/${stepId}`,
          {
            method: "DELETE",
            headers: {
              "Content-Type": "application/json",
              'Authorization': 'Bearer ' + localStorage.getItem('token'),
            },
          },
        );

        if (!response.ok) {
          throw new Error("Failed to delete step");
        }

        console.log("Step deleted successfully");
      } catch (error) {
        console.error("Error deleting step:", error);
      }
    }

    const updatedSteps = [...formData.steps];
    updatedSteps.splice(index, 1);
    setFormData({ ...formData, steps: updatedSteps });
  };

  const handleStepChange = (e, index) => {
    const { name, value } = e.target;
    const updatedSteps = [...formData.steps];
    updatedSteps[index][name] = value;
    setFormData({ ...formData, type: "event", steps: updatedSteps });
  };

  const handleUpdateClick = () => {
    handleUpdateActivitySteps(selectedEvent.id, {
      ...formData,
      type: "event",
      steps: editedSteps,
    });
    closeModal();
  };
  // console.log(start_time)
  if (!isOpen) return null;
  console.log("formdata", formData);

  const handleSubmit = async (e) => {
    // e.preventDefault();
    const filteredSteps = formData.steps.filter(
      (step) => step.title.trim() !== "" && step.content.trim() !== "",
    );

    const formDataWithDefaultType = {
      ...formData,
      user_id: clientId,
      type: formData.type || "event",
      steps: filteredSteps,
      reminder_enabled: reminderEnabled,
      event_state: isComplete ? "completed" : "incomplete",
    };
    const requiredFields = [
      "user_id",
      "title",
      "type",
      "start_time",
      "end_time",
    ];
    const missingFields = requiredFields.filter((field) => !formData[field]);

    if (missingFields.length > 0) {
      alert(
        `Please complete the following fields: ${missingFields.join(", ")}.`,
      );
      return;
    }

    const url =
      typeof formData.id === "number"
        ? `${BASE_URL}/activities/${formData.id}`
        : `${BASE_URL}/activities`;

    const method = typeof formData.id === "number" ? "PATCH" : "POST";

    try {
      console.log("formdatawithdefault", formDataWithDefaultType);
      const response = await fetch(url, {
        method: method,
        headers: {
          "Content-Type": "application/json",
          'Authorization': 'Bearer ' + localStorage.getItem('token'),
        },
        body: JSON.stringify(formDataWithDefaultType),
      });

      if (!response.ok) {
        throw new Error("Failed to add activity");
      }

      const data = await response.json();

      if (method === "POST") {
        console.log("Event created successfully:", data);
      } else {
        console.log("Event updated successfully:", data);
      }

      setFormData({
        ...data.activity,
        steps: data.activity.steps,
      });

      closeModal();
    } catch (error) {
      console.error("Error adding activity:", error);
    }
  };

  const handleToggleReminder = (e) => {
    const newValue = e.target.checked;
    setReminderEnabled(newValue);
  };

  const handleAddInstructionsClick = () => {
    setShowSteps(!showSteps);
  };

  const handleDeleteActivityButton = async (activityId) => {
    console.log("handleDeleteActivityButton activityId", activityId);
    try {
      const response = await fetch(
        `${BASE_URL}/activities/${activityId}`,
        {
          method: "DELETE",
          headers: {
            'Authorization': 'Bearer ' + localStorage.getItem('token'),
          }
        },
      );
      if (response.ok) {
        console.log(`Activity ${activityId} deleted successfully.`);
        closeModal();
      } else {
        const errorData = await response.json();
        console.error("Failed to delete the activity:", errorData.message);
        // Update UI or state to show the error message
        // this.setState({ error: errorData.message });
      }
    } catch (error) {
      console.error("Network error:", error);
      // Update UI or state to show network error
      // this.setState({ error: "Network error, please try again later." });
    }
  };

  const handleMarkComplete = async (e) => {
    // console.log("working!!!!!!!!!!!!!!!!!!!!");
    e.stopPropagation();
    // console.log("marking");
    const newValue = !isComplete;
    setIsComplete(newValue);
    try {
      const updatedActivity = {
        ...formData,
        event_state: newValue ? "completed" : "incomplete",
        title: formData.title, // Ensure title is included
        description: formData.description, // Ensure description is included
        start_time: formData.start_time,
        end_time: formData.end_time,
        steps: formData.steps,
        updated_on: new Date().toISOString(),
      };

      const response = await fetch(
        `${BASE_URL}/activities/${formData.id}`,
        {
          method: "PATCH",
          headers: {
            "Content-Type": "application/json",
            'Authorization': 'Bearer ' + localStorage.getItem('token'),
          },
          body: JSON.stringify(updatedActivity),
        },
      );
      if (response.ok) {
        console.log("update!!!!!!!!!!!", updatedActivity);
        console.log("Activity marked completed.");
      } else {
        console.error("Failed to mark the activity as completed.");
      }
    } catch (error) {
      console.error("Network error:", error);
    }
  };

  const handleSuretyClick = () => {
    setSuretyClicked(true);
  };

  return (
    <div className="modal-overlay">
      <div className="modal" style={{display: isOpen ? 'inline-block' : 'none' }}>
        <div className="modal-content">
          <span className="close" onClick={closeModal}>
            &times;
          </span>
          <h2>Create an activity for your client</h2>

          <div className="activity-container">
            <div className="input-container activity-name">
              <label htmlFor="activity-name">Activity name </label>
              <input
                type="text"
                id="activity-name"
                value={formData.title}
                placeholder={formData.title || ""}
                onChange={(e) => handleActivityChange("title", e.target.value)}
              />
            </div>
            <div className="input-container activity-description">
              <label htmlFor="description">Description </label>
              <textarea
                id="description"
                value={formData.description}
                onChange={(e) =>
                  handleActivityChange("description", e.target.value)
                }
              />
            </div>
            <div className="input-container">
              <label htmlFor="reminder-toggle" className="small-font-label">
                Send reminders to client about activity
              </label>
              <label className="switch">
                <input
                  type="checkbox"
                  id="reminder-toggle"
                  checked={reminderEnabled}
                  onChange={handleToggleReminder}
                />
                <span className="slider round"></span>
              </label>
            </div>
            <div className="input-container">
              <label htmlFor="complete-toggle" className="small-font-label">
                Mark activity complete
              </label>
              <label className="switch">
                <input
                  type="checkbox"
                  id="complete-toggle"
                  checked={isComplete}
                  onChange={handleMarkComplete}
                />
                <span className="slider round"></span>
              </label>
            </div>

            <div className="input-container">
              <label htmlFor="start-time">Start</label>
              <input
                type="datetime-local"
                id="start-time"
                value={formData.start_time}
                onChange={(e) =>
                  handleActivityChange("start_time", e.target.value)
                }
              />
            </div>

            <div className="input-container">
              <label htmlFor="end-time">End</label>
              <input
                type="datetime-local"
                id="end-time"
                value={formData.end_time}
                onChange={(e) =>
                  handleActivityChange("end_time", e.target.value)
                }
              />
            </div>
          </div>
          {/* <button onClick={handleMarkComplete}>Mark activity complete</button> */}

          <button
            className="addInstructionsButton"
            onClick={handleAddInstructionsClick}
          >
            Add instructions
          </button>

          {showSteps && (
            <div className="task-steps">
              {formData.steps.map((step, index) => (
                <div key={index} className="step-container">
                  <div className="input-container step-name">
                    <label htmlFor={`step-title-${index}`}>Name</label>
                    <input
                      type="text"
                      id={`step-title-${index}`}
                      className="step-title"
                      name="title" // Ensure the name attribute is set correctly
                      placeholder={`Step ${index + 1} Title`}
                      value={step.title}
                      maxLength={150}
                      onChange={(e) => handleStepChange(e, index)}
                    />
                    <button
                      type="button"
                      className="removeStepButton"
                      onClick={() => removeStep(index)}
                    >
                      Remove step
                    </button>
                  </div>
                  <div className="input-container step-description">
                    <label htmlFor={`step-content-${index}`}>Content</label>
                    <textarea
                      id={`step-content-${index}`}
                      className="step-content"
                      name="content" // Ensure the name attribute is set correctly
                      placeholder={`Step ${index + 1} Content`}
                      value={step.content}
                      maxLength={250}
                      onChange={(e) => handleStepChange(e, index)}
                      rows="5"
                    />
                  </div>
                  <div className="button-container">
                    <button
                      type="button"
                      className="addStepButton"
                      onClick={addStep}
                    >
                      Add next step
                    </button>
                  </div>
                </div>
              ))}
            </div>
          )}

          {isCaregiver && (
            <div>
              <button
                onClick={(e) => {
                  handleSubmit();
                }}
              >
                Save
              </button>
            </div>
          )}

          <button
            className="delete-activity-button"
            onClick={handleSuretyClick}
          >
            Delete Activity
          </button>

          {suretyClicked && (
            <div>
              <button
                className="surety-delete-activity-button"
                onClick={(e) => handleDeleteActivityButton(formData.id)}
              >
                Permanently Delete Activity
              </button>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default EventModal;
