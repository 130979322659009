import React, { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { withAuthenticationRequired } from "@auth0/auth0-react";
import checkCircularImage from "../src/assets/check_circular.png";
import "./AssignmentSetSucessPage.css";
import LayoutPlain from "./LayoutPlain";

const AssignmentSetSucessPage = () => {
    const [didMount, setDidMount] = useState(false);
    const navigate = useNavigate();
    const location = useLocation();

    useEffect(() => {
        if (didMount) {
            return;
        }
        setDidMount(true);
    }, [didMount]);

    return !didMount ? null : (
        <LayoutPlain>
            <div style={{ width: '50%', margin: 'auto' }}>
                <img
                    src={checkCircularImage}
                    alt="Assignment Set Success"
                    style={{ width: '150px', height: '150px' }}
                />
                <h1 style={{  fontSize: '2rem' }}>Your new assignment is set</h1>
                <p style={{ fontSize: '1.2rem' }}>We emailed the caregivers to let them know about their new clients, and the caregivers can now provide remote care to the clients you assigned them to.</p>
                <button
                    class="btn-branding"
                    style={{ color: '#000', backgroundColor: '#66A77B', padding: '10px 20px', fontSize: '1.2rem' }}
                    onClick={(ev) => {
                        ev.preventDefault();
                        navigate('/assignment_center');
                    }}
                >
                    Got it
                </button>
            </div>
        </LayoutPlain>
    );
};

export default withAuthenticationRequired(AssignmentSetSucessPage);
