import React, { useState, useEffect } from "react";
import { useAuth0, withAuthenticationRequired } from "@auth0/auth0-react";
import { useCaregiver } from "./CaregiverContext";
import LayoutPlain from "./LayoutPlain";
import "./AddClientPage.css";

const BASE_URL = process.env.REACT_APP_BASE_URL;

const AddClientPage = () => {
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const { caregiverId, setCaregiverId } = useCaregiver();
  const [message, setMessage] = useState("");
  const [caregivers, setCaregivers] = useState([]);
  const [selectedCaregiver, setSelectedCaregiver] = useState();

  useEffect(() => {
    if (localStorage.getItem('isAdmin') == 'true') {
      setCaregiverId(localStorage.getItem('caregiverId'));
      setSelectedCaregiver(localStorage.getItem('caregiverId'));
    }

    fetchCaregivers();
  }, []);

  const fetchCaregivers = async () => {
    console.log("fetchCaregivers...");
    try {
      const response = await fetch(`${BASE_URL}/caregivers`, {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      });
      if (response.ok) {
        const data = await response.json();
        setCaregivers(data);
      } else {
        console.error("Failed to fetch caregivers for caregiver");
      }
    } catch (error) {
      console.error("Error fetching caregivers for caregiver:", error);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const name = firstName + " " + lastName;
      const userType = "client";

      const params = {
        email,
        password: (Math.random() + 1).toString(36),
        name: name,
        given_name: firstName,
        family_name: lastName,
        connection: "Username-Password-Authentication",
        user_metadata: {},
      };

      if (userType) {
        params.user_metadata.user_type = userType;
      }

      params.user_metadata.register_data = {
        email,
        name,
        first_name: firstName,
        last_name: lastName,
        caregiver_id: caregiverId,
        created_by: localStorage.getItem("caregiverId"),
      };

      const response = await fetch(
        `https://${process.env.REACT_APP_AUTH0_DOMAIN}/api/v2/users`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + localStorage.getItem("token"),
          },
          body: JSON.stringify(params),
        }
      );

      const data = await response.json();

      if (response.ok) {
        alert("Client created"); // Notify success
        setFirstName("");
        setLastName("");
        setEmail("");
        setCaregiverId("");
        setSelectedCaregiver(null);
        window.location.href = "/select_client_homepage";
      } else {
        alert("Error adding client: " + data.message); // Notify error
      }
    } catch (error) {
      alert("Error adding client: " + error.message); // Notify error
    }
  };

  const handleCaregiversSelectionChange = (e) => {
    const caregiverId = e.target.value;
    setCaregiverId(caregiverId);
    setSelectedCaregiver(caregiverId);
    localStorage.setItem("selectedCaregiver", caregiverId);
  };

  return (
    <LayoutPlain>
      <div style={{ width: "50%" }}>
        <h1>Add New Client</h1>
        <form onSubmit={handleSubmit}>
          {message && <p>{message}</p>}
          <div className="input-container">
            <label htmlFor="firstName">First Name:</label>
            <input
              type="text"
              id="firstName"
              value={firstName}
              onChange={(e) => setFirstName(e.target.value)}
              required
            />
          </div>
          <div className="input-container">
            <label htmlFor="lastName">Last Name:</label>
            <input
              type="text"
              id="lastName"
              value={lastName}
              onChange={(e) => setLastName(e.target.value)}
              required
            />
          </div>
          <div className="input-container">
            <label htmlFor="email">Email:</label>
            <input
              type="email"
              id="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required
            />
          </div>
          {localStorage.getItem('isSuperAdmin') == 'true' ?
          (<div className="input-container">
            <label htmlFor="caregiverId">Caregiver:</label>
            <select
              style={{ marginLeft: "125px" }}
              value={selectedCaregiver}
              onChange={handleCaregiversSelectionChange}
            >
              <option value="">Select a caregiver</option>
              {caregivers.map((caregiver) => (
                <option key={caregiver.id} value={caregiver.id}>
                  {caregiver.name}
                </option>
              ))}
            </select>
          </div>) : (null)}
          <button type="submit" className="addStepButton">
            Save
          </button>
        </form>
      </div>
    </LayoutPlain>
  );
};

export default withAuthenticationRequired(AddClientPage);
