import React, { useState } from "react";
import { useAuth0, withAuthenticationRequired } from "@auth0/auth0-react";
import { useNavigate, useLocation } from "react-router-dom";
import { useCaregiver } from "../CaregiverContext";
import LayoutPlain from "../LayoutPlain";
import Select from 'react-select';
import "./AddCaregiverPage.css";

const AddCaregiverPage = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [title, setTitle] = useState("");
  const [company, setCompany] = useState("");
  const [responsibility, setResponsibility] = useState("");
  const [city, setCity] = useState("");
  const [state, setState] = useState("");
  const [phone, setPhone] = useState("");
  const { caregiverId, setCaregiverId } = useCaregiver();
  const [message, setMessage] = useState("");

  const isFormForAdmin = location.pathname.includes("/add-admin");

  const responsibilityOptions = [
    { label: "Assisted living center", value: "Assisted living center" },
    { label: "Group home", value: "Group home" },
    { label: "Doctor's office", value: "Doctor's office" },
    { label: "Aging on aging office", value: "Aging on aging office" },
    { label: "Certified nursing assistant", value: "Certified nursing assistant" },
    { label: "Licensed practical nurse", value: "Licensed practical nurse" },
    { label: "Registered nurse", value: "Registered nurse" },
    { label: "Physical or occupational therapist", value: "Physical or occupational therapist" },
    { label: "Friend or family member", value: "Friend or family member" },
    { label: "Other", value: "Other" },
  ];

  const handleSubmit = async (e) => {
    e.preventDefault();

    const userType = isFormForAdmin ? "caregiver_admin" : "caregiver";

    try {
      const response = await fetch(`${process.env.REACT_APP_BASE_URL}/users/invite`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + localStorage.getItem('token'),
        },
        body: JSON.stringify({
          name: firstName + ' ' + lastName,
          first_name: firstName,
          last_name: lastName,
          email,
          user_type: userType,
          title, company, city, state, phone, responsibility,
          created_by: localStorage.getItem('caregiverId'),
        }),
      });

      const data = await response.json();

      if (response.ok) {
        navigate('/invite-sent', { state: { user: data.user } });
        setFirstName("");
        setLastName("");
        setEmail("");
        setTitle("");
        setCompany("");
        setCity("");
        setState("");
        setPhone("");
        setResponsibility("");
        setCaregiverId("");
        localStorage.setItem('selectedCaregiver', '');
      } else {
        alert('Error inviting: ' + data.message); // Notify error
      }
    } catch (error) {
      alert('Error inviting: ' + error.message); // Notify error
    }
  };

  const onInputChangeResponsibility = (
    newValue, action
  ) => {
    setResponsibility(newValue.value);
  };

  return (
    <LayoutPlain>
      <div style={{ width: "50%" }}>
        <h1>Add New {isFormForAdmin ? 'Admin' : 'Caregiver'}</h1>
        <h4>Add the caregiver’s info and invite them to Compass Care. After the caregiver registers their account, you can assign them to clients. </h4>
        <form onSubmit={handleSubmit}>
          {message && <p>{message}</p>}
          <div className="input-container">
            <label htmlFor="firstName">First Name:</label>
            <input
              type="text"
              id="firstName"
              value={firstName}
              onChange={(e) => setFirstName(e.target.value)}
              required
            />
          </div>
          <div className="input-container">
            <label htmlFor="lastName">Last Name:</label>
            <input
              type="text"
              id="lastName"
              value={lastName}
              onChange={(e) => setLastName(e.target.value)}
              required
            />
          </div>
          <div className="input-container">
            <label htmlFor="title">Title:</label>
            <input
              type="text"
              id="title"
              value={title}
              onChange={(e) => setTitle(e.target.value)}
              required
            />
          </div>
          <div className="input-container">
            <label htmlFor="company">Company Name:</label>
            <input
              type="text"
              id="company"
              value={company}
              onChange={(e) => setCompany(e.target.value)}
              required
            />
          </div>
          <div className="input-container">
            <label htmlFor="responsibility">Responsibility:</label>
            <div style={{ marginLeft: 'auto', width: '70%' }}>
              <Select
                defaultValue={[]}
                name="responsibilities"
                onChange={onInputChangeResponsibility}
                options={responsibilityOptions}
                placeholder="Select caregiver responsibility"
                classNamePrefix="select" />
            </div>
          </div>
          <div className="input-container">
            <label htmlFor="email">Email:</label>
            <input
              type="email"
              id="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required
            />
          </div>
          <div className="input-container">
            <label htmlFor="phone">Phone:</label>
            <input
              type="text"
              id="phone"
              value={phone}
              onChange={(e) => setPhone(e.target.value)}
              required
            />
          </div>
          <div className="input-container">
            <label htmlFor="city">City:</label>
            <input
              type="text"
              id="city"
              value={city}
              onChange={(e) => setCity(e.target.value)}
              required
            />
          </div>
          <div className="input-container">
            <label htmlFor="state">State:</label>
            <input
              type="text"
              id="state"
              value={state}
              onChange={(e) => setState(e.target.value)}
              required
            />
          </div>
          <div style={{ textAlign: 'right' }}>
            <button type="submit" className="btn-branding" onClick={(e) => {
              e.preventDefault();
              navigate(-1);
            }}>
              Cancel
            </button>
            <button type="submit" className="btn-branding">
              Send Invite
            </button>
          </div>
        </form>
      </div>
    </LayoutPlain>
  );
};

export default withAuthenticationRequired(AddCaregiverPage);
