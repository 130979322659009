import React, { useState, useEffect } from "react";
import { useNavigate, useLocation, useParams } from "react-router-dom";
import { withAuthenticationRequired } from "@auth0/auth0-react";
import "./ClientProfilePage.css";
import LayoutPlain from "./LayoutPlain";

const BASE_URL = process.env.REACT_APP_BASE_URL;

const ClientProfilePage = () => {
  const [userType, setUserType] = useState("");
  const [userId, setUserId] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [title, setTitle] = useState("");
  const [city, setCity] = useState("");
  const [state, setState] = useState("");
  const [company, setCompany] = useState("");
  const [phone, setPhone] = useState("");
  const [message, setMessage] = useState("");
  const [didMount, setDidMount] = useState(false);
  const [isEditable, setIsEditable] = useState(false);
  const [references, setReferences] = useState([]);
  const navigate = useNavigate();
  const location = useLocation();
  const { userProfileId } = useParams();

  useEffect(() => {
    console.log(`userProfileId: ${userProfileId}`)
    if (didMount) {
      return;
    }

    if (location.state && location.state.user) {
      setDidMount(true);

      const user = location.state.user;

      if (location.pathname.includes("caregiver")) {
        setUserType("caregiver");
        fetchClients(user.id);
      }

      if (location.pathname.includes("client")) {
        setUserType("client");
        setReferences(user.caregivers);
      }

      setUser(user);
    }
  }, [didMount]);

  const fetchClients = async (caregiverId) => {
    let url = `${BASE_URL}/caregivers/${caregiverId}/clients`;
    try {
      const response = await fetch(url, {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      });
      if (response.ok) {
        const data = await response.json();
        setReferences(data);
      } else {
        console.error("Failed to fetch caregivers");
      }
    } catch (error) {
      console.error("Error fetching caregivers", error);
    }
  };

  function setUser(user) {
    setUserId(user.id);
    setFirstName(user.first_name);
    setLastName(user.last_name);
    setName(user.first_name + " " + (user.last_name ?? ""));
    setEmail(user.email);
    setPhone(user.phone);
    setTitle(user.title);
    setCompany(user.company);
    setCity(user.city);
    setState(user.state);
  }

  const handleSubmit = async (e) => {
    e.preventDefault();

    alert("Work In Progress...");
  };

  const handleDelete = async (e) => {
    e.preventDefault();

    alert("Work In Progress... Pending soft delete implementation");
    return;

    let userTypeName = "client";

    if (
      window.confirm(`Are you sure you wanna delete this ${userTypeName}?`) == false
    ) {
      return;
    }

    const params = {
      userId: localStorage.getItem("caregiverId"),
    };

    try {
      const response = await fetch(`${BASE_URL}/${userTypeName}s/${userId}`, {
        method: "DELETE",
        headers: {
          'Content-Type': 'application/json',
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
        body: JSON.stringify(params),
      });
      if (response.ok) {
        alert(`${userTypeName} deleted successfully`);
        navigate("/assignment_center");
      } else {
        console.error(`Failed to delete ${userTypeName}`);
      }
    } catch (error) {
      console.error(`Error deletting ${userTypeName}`, error);
    }
  }

  function toTitleCase(str) {
    return str
      .replace(/_/g, " ")
      .replace(
        /\w\S*/g,
        (text) => text.charAt(0).toUpperCase() + text.substring(1).toLowerCase()
      );
  }

  return !didMount ? null : (
    <LayoutPlain>
      <div style={{ display: "flex" }}>
        <h1 style={{ textAlign: "left" }}>{toTitleCase(name)}
          {localStorage.getItem("isSuperAdmin") === "true" ?
            (<button className={`delete-${userType}`} onClick={handleDelete}>
              Delete {userType}
            </button>) : (null)}
        </h1>
        <div style={{ width: "50%" }}>
          <h1 style={{ textAlign: "left" }}>{toTitleCase(userType)} Info</h1>
          <button
            onClick={() => setIsEditable(!isEditable)}
            style={{ position: "relative" }}
          >
            Edit
          </button>
          <form onSubmit={handleSubmit}>
            <div className="input-container">
              <label htmlFor="email">Email:</label>
              <input
                type="email"
                id="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                required
                disabled
              />
            </div>
            {message && <p>{message}</p>}
            <div className="input-container">
              <label htmlFor="firstName">First Name:</label>
              <input
                type="text"
                id="firstName"
                value={firstName}
                onChange={(e) => setFirstName(e.target.value)}
                required
                disabled={!isEditable}
              />
            </div>
            <div className="input-container">
              <label htmlFor="lastName">Last Name:</label>
              <input
                type="text"
                id="lastName"
                value={lastName}
                onChange={(e) => setLastName(e.target.value)}
                required
                disabled={!isEditable}
              />
            </div>
            <div className="input-container">
              <label htmlFor="title">Title:</label>
              <input
                type="text"
                id="title"
                value={title}
                onChange={(e) => setTitle(e.target.value)}
                required
                disabled={!isEditable}
              />
            </div>
            <div className="input-container">
              <label htmlFor="company">Company:</label>
              <input
                type="text"
                id="company"
                value={company}
                onChange={(e) => setCompany(e.target.value)}
                required
                disabled={!isEditable}
              />
            </div>
            <div className="input-container">
              <label htmlFor="city">City:</label>
              <input
                type="text"
                id="city"
                value={city}
                onChange={(e) => setCity(e.target.value)}
                required
                disabled={!isEditable}
              />
            </div>
            <div className="input-container">
              <label htmlFor="state">State:</label>
              <input
                type="text"
                id="state"
                value={state}
                onChange={(e) => setState(e.target.value)}
                required
                disabled={!isEditable}
              />
            </div>
            <div className="input-container">
              <label htmlFor="state">Phone:</label>
              <input
                type="text"
                id="phone"
                value={phone}
                onChange={(e) => setPhone(e.target.value)}
                required
                disabled={!isEditable}
              />
            </div>
            <button type="submit" className="addStepButton">
              Save
            </button>
          </form>
        </div>

        <div style={{ width: "50%", height: "300px" }}>
          <h1 style={{ textAlign: "left" }}>
            {`${toTitleCase(userType)}'s `}
            {userType == "client" ? "caregivers" : "clients"}
          </h1>
          {/* TODO: Load resources */}
          <p>
            {references.length > 0 ? (
              <span>
                Need to change the {userType == "client" ? "caregivers" : "clients"} assigned to this {userType}?&nbsp;
              </span>
            ) : (
              <span>This {`${userType}`} isn’t assigned to any {userType == "client" ? "caregivers" : "clients"}.&nbsp;</span>
            )}
            <a
              href=""
              onClick={(ev) => {
                ev.preventDefault();
                navigate("/assignment_center");
              }}
            >
              Go to Assignment Center
            </a>
            .
          </p>
          <table>
            <tbody>
              {(references ?? []).map((ref) => (
                <tr key={ref.id}>
                  <td>{ref.name}</td>
                  <td>
                    <a
                      href=""
                      onClick={(ev) => {
                        ev.preventDefault();
                        navigate(
                          `/${userType == "client" ? "caregiver" : "client"
                          }_profile/${ref.id}`,
                          { state: { user: ref } }
                        );
                      }}
                    >
                      Go to {userType == "client" ? "caregiver" : "client"}
                    </a>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </LayoutPlain>
  );
};

export default withAuthenticationRequired(ClientProfilePage);
