import React, { useState, useEffect, useContext } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { withAuthenticationRequired } from "@auth0/auth0-react";
import LayoutPlain from "./LayoutPlain";
import { ClientContext } from "./ClientContext";
import "./AssignmentCenterPage.css";

const BASE_URL = process.env.REACT_APP_BASE_URL;

const AssignmentCenterHeader = ({ children }) => {
  const navigate = useNavigate();

  return (
    <div>
      <table>
        <tbody>
          <tr>
            <td>
              <h1 style={{ textAlign: "left", fontSize: "2.6rem" }}>
                <strong>Assignment Center</strong>
              </h1>
              <h5>Manage which clients are assigned to which admins.</h5>
            </td>
            <td style={{ textAlign: "right", verticalAlign: "bottom" }}>
              <button
                className="btn-branding-plain"
                style={{
                  backgroundColor: "#A7A466",
                }}
                onClick={() => navigate("/add_new_assignment")}
              >
                Add new assignment
              </button>
            </td>
            {children}
          </tr>
        </tbody>
      </table>
    </div>
  );
};

const AssignmentCenterPage = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [didMount, setDidMount] = useState(false);
  const { clients, setClients } = useContext(ClientContext);

  useEffect(() => {
    setDidMount(true);
  }, [didMount]);

  async function handleUnassignCaregiverClick(ev, client, caregiverId) {
    ev.preventDefault();

    if (
      window.confirm("Are you sure you wanna unassign this caregiver?") == false
    ) {
      return;
    }

    const response = await fetch(
      `${BASE_URL}/clients/${client.id}/unassignCaregiver`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
        body: JSON.stringify({
          caregiverId: caregiverId,
        }),
      }
    );

    const data = await response.json();

    if (response.ok) {
      alert("Unassigned");

      const clientDup = client;
      const index = clientDup.caregivers.findIndex(
        (item) => item.id === caregiverId
      );

      clientDup.caregivers = clientDup.caregivers.filter(
        (item, i) => i !== index
      );

      const clientIndex = clients.findIndex((item) => item.id === clientDup.id);

      let clientsDup = clients;
      clientsDup = clientsDup.filter((item, i) => i !== clientIndex);
      clientsDup.push(clientDup);

      setClients(clientsDup);
    }
  }

  return !didMount ? null : (
    <LayoutPlain>
      <div className="container-assignment-center">
        <AssignmentCenterHeader />
        <div className="container-clients-caregivers">
          <table className="tbl-clients-caregivers">
            <tbody>
              <tr className="tbl-header">
                <th>Clients</th>
                <th colSpan={2}>Caregivers</th>
              </tr>
              {clients.map((client) => (
                <tr className="tbl-rows" key={client.id}>
                  <td className="client-info">
                    <p>
                      <strong>{client.name}</strong>
                    </p>
                    <a
                      href=""
                      onClick={(ev) => {
                        ev.preventDefault()
                        navigate(`/client_profile/${client.id}`, { state: { user: client } })
                      }}
                    >
                      Go to Client
                    </a>
                  </td>
                  <td
                    style={
                      (client.caregivers ?? []).length > 0
                        ? { width: "250px" }
                        : { textAlign: "center" }
                    }
                    colSpan={(client.caregivers ?? []).length > 0 ? 1 : 2}
                  >
                    {(client.caregivers ?? []).length > 0 ? (
                      <span>is assigned to</span>
                    ) : (
                      <span>isn’t assigned to any caregivers yet.</span>
                    )}
                  </td>
                  {(client.caregivers ?? []).length < 1 ? null : (
                    <td>
                      {(client.caregivers ?? []).map((caregiver) => (
                        <div style={{ display: "flex" }} key={caregiver.id}>
                          <strong style={{ minWidth: "400px" }}>
                            {caregiver.id != localStorage.getItem("caregiverId")
                              ? caregiver.name
                              : "Me"}
                          </strong>
                          {caregiver.id ==
                            localStorage.getItem("caregiverId") ? null : (
                            <div>
                              <a
                                href=""
                                onClick={(ev) => {
                                  ev.preventDefault();
                                  navigate(`/caregiver_profile/${caregiver.id}`, {
                                    state: { user: caregiver },
                                  });
                                }}
                              >
                                Go to caregiver
                              </a>
                              &nbsp; | &nbsp;
                              <a
                                href=""
                                onClick={(ev) =>
                                  handleUnassignCaregiverClick(
                                    ev,
                                    client,
                                    caregiver.id
                                  )
                                }
                              >
                                Unassign caregiver
                              </a>
                            </div>
                          )}
                        </div>
                      ))}
                    </td>
                  )}
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </LayoutPlain>
  );
};

export default withAuthenticationRequired(AssignmentCenterPage);
