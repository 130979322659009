import React, { useState, useEffect, useContext } from "react";
import { useAuth0, withAuthenticationRequired } from "@auth0/auth0-react";
import { useNavigate } from "react-router-dom";
import { useCaregiver } from "./CaregiverContext";
import { ClientContext } from "./ClientContext";
import Select from 'react-select';
import { DataObject } from "@mui/icons-material";

require("dotenv").config();
const BASE_URL = process.env.REACT_APP_BASE_URL;

function LayoutPlain({ children }) {
  const { user, isAuthenticated, isLoading, logout } = useAuth0();

  const {
    selectedClient,
    setSelectedClient,
    clients,
    setClients,
  } = useContext(ClientContext);
  const [activities, setActivities] = useState([]);
  const [caregivers, setCaregivers] = useState([]);
  const [selectedCaregiver, setSelectedCaregiver] = useState(
    localStorage.getItem("selectedCaregiver") || ""
  );
  const { caregiverId, isCaregiver } = useCaregiver();
  const [contacts, setContacts] = useState([]);
  const [clientOptions, setClientOptions] = useState([]);
  const [caregiverOptions, setCaregiverOptions] = useState([]);
  const navigate = useNavigate();

  const handleHomepageClick = () => {
    setSelectedClient("");
    localStorage.removeItem("selectedClient");
    navigate("../select-client-homepage", {
      state: { caregiverId: localStorage.getItem("caregiverId") },
    });
  };

  useEffect(() => {
    if (selectedClient) {
      const fetchActivities = async () => {
        try {
          const response = await fetch(
            `${BASE_URL}/clients/${selectedClient}/activities`,
            {
              headers: {
                Authorization: "Bearer " + localStorage.getItem("token"),
              },
            }
          );
          if (response.ok) {
            const data = await response.json();
            console.log("schmp data", data);
            setActivities(data);
            navigate(`/calendar/${selectedClient}`, {
              state: { isCaregiver: true },
            });
          } else {
            console.error("Failed to fetch activities for client");
          }
        } catch (error) {
          console.error("Error fetching activities for client:", error);
        }
      };

      fetchActivities();
    }
  }, [selectedClient]);

  useEffect(() => {
    const fetchClients = async () => {
      let url = `${BASE_URL}/caregivers/${caregiverId}/clients`;
      if (localStorage.getItem("isSuperAdmin") == "true") {
        url = `${BASE_URL}/clients`;
      }
      try {
        const response = await fetch(url, {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("token"),
          },
        });
        if (response.ok) {
          const data = await response.json();
          setClients(data);
          let options = [];
          if (localStorage.getItem("isSuperAdmin") == "true" || localStorage.getItem('isAdmin')) {
            options.push({ value: 'create', label: '--- Add new client ---' });
          }
          data.forEach((c) => { options.push(c) });
          setClientOptions(options)
        } else {
          console.error("Failed to fetch clients for caregiver");
        }
      } catch (error) {
        console.error("Error fetching clients for caregiver:", error);
      }
    };

    if (caregiverId) {
      fetchClients();
    }
  }, [caregiverId, setClients]);

  useEffect(() => {
    const fetchCaregivers = async () => {
      let url = `${BASE_URL}/caregivers?user_id=${localStorage.getItem(
        "caregiverId"
      )}`;
      if (localStorage.getItem("isSuperAdmin")) {
        url = `${BASE_URL}/caregivers`;
      }
      try {
        const response = await fetch(url, {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("token"),
          },
        });
        if (response.ok) {
          const data = await response.json();
          setCaregivers(data);
          let options = [];
          if (localStorage.getItem("isSuperAdmin") == "true") {
            options.push({ value: 'create', label: '--- Add new admin ---' });
          }
          if (localStorage.getItem("isAdmin") == "true") {
            options.push({ value: 'create', label: '--- Add new caregiver ---' });
          }
          data.forEach((c) => { options.push(c) });
          setCaregiverOptions(options);
        } else {
          console.error("Failed to fetch caregivers");
        }
      } catch (error) {
        console.error("Error fetching caregivers", error);
      }
    };

    if (
      localStorage.getItem("isSuperAdmin") == "true" ||
      localStorage.getItem("isAdmin") == "true"
    ) {
      fetchCaregivers();
    }
  }, [caregiverId, setCaregivers]);

  const onInputChangeCaregiver = (
    newValue, action
  ) => {
    const caregiverId = newValue.value;

    if (caregiverId == "create") {
      navigate(localStorage.getItem('isSuperAdmin') ? '/add-admin' : '/add-caregiver');
      return;
    }
    setSelectedCaregiver(caregiverId);
    localStorage.setItem("selectedCaregiver", caregiverId);

    if (localStorage.getItem("isSuperAdmin") == "true" || localStorage.getItem("isAdmin") == "true") {
      const user = caregivers.find((user) => user.id === parseInt(caregiverId));
      navigate(`/caregiver_profile/${user.id}`, { state: { user: user } });
    }
  };

  const onInputChangeClient = (
    newValue, action
  ) => {
    const clientId = newValue.value;

    if (clientId == "create") {
      navigate('/add-client');
      return;
    }
    setSelectedClient(clientId);
    localStorage.setItem("selectedClient", clientId);

    if (localStorage.getItem("isSuperAdmin") == "true") {
      const user = clients.find((user) => user.id === parseInt(clientId));
      navigate(`/client_profile/${user.id}`, { state: { user: user } });
    }
  };

  return (
    <div className="home-page">
      <header className="header">
        <div className="inner">
          <button className="logo-button" onClick={handleHomepageClick}>
            <img
              src="/images/Compass-Care_White.png"
              alt="Logo"
              style={{ marginLeft: "10px", width: "200px" }}
            />
          </button>
          <div className="sch-client-selector" style={{ paddingRight: '20px' }}>
            <Select
              defaultValue={1}
              name="clients"
              onChange={onInputChangeClient}
              options={clientOptions}
              value={selectedClient}
              className="basic-single-select"
              classNamePrefix="select"
              placeholder="Search Clients"
            />
          </div>
          <div style={{ width: '25%' }}>
            <Select
              defaultValue={1}
              name="carigivers"
              onChange={onInputChangeCaregiver}
              options={caregiverOptions}
              value={selectedCaregiver}
              className="basic-single-select"
              classNamePrefix="select"
              placeholder="Search Caregivers"

            />
          </div>
        </div>
        <button
          className="btn-branding"
          style={{ width: "150px" }}
          onClick={(ev) => {
            ev.preventDefault();
            logout({
              logoutParams: { returnTo: window.location.origin + "/login" },
            });
            localStorage.clear();
          }}
        >
          Log Out
        </button>
      </header>
      <div className="main-content-container">
        <div className="main-content">{children}</div>
      </div>
    </div>
  );
}

export default withAuthenticationRequired(LayoutPlain);
