import React, { useState, useEffect } from 'react';
import  { useSearchParams, useNavigate } from "react-router-dom";
import axios from 'axios';
import './RegisterUserPage.css';

const RegisterUserPage = () => {
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [userType, setUserType] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [title, setTitle] = useState('');
  const [city, setCity] = useState('');
  const [state, setState] = useState('');
  const [company, setCompany] = useState('');
  const [phone, setPhone] = useState('');
  const [createdBy, setCreatedBy] = useState('');
  const [message, setMessage] = useState('');
  const [searchParams, setSearchParams] = useSearchParams();
  const [didMount, setDidMount] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    localStorage.clear();

    getAccessToken();
  }, [didMount]);


  useEffect(() => {
    if (didMount) { return; }

    const invite_code = searchParams.get("invite_code");

    if (invite_code) {
      fetchUser(invite_code)
    } else {
      navigate('/login')
    }
  }, [didMount]);

  async function getAccessToken() {
    if (localStorage.getItem('token') != null) { return; }

    // TODO: Convert to fetch then remove axios
    await axios.post('https://' + process.env.REACT_APP_AUTH0_DOMAIN + '/oauth/token', {
        client_id: process.env.REACT_APP_AUTH0_BE_CLIENT_ID,
        client_secret: process.env.REACT_APP_AUTH0_BE_CLIENT_SECRET,
        audience: "https://" + process.env.REACT_APP_AUTH0_DOMAIN + "/api/v2/",
        grant_type: "client_credentials"
    }, {
        headers: { 'content-type': 'application/json' },
    }).then(response => {
        localStorage.setItem('token', response.data.access_token);
    });
  }

  async function fetchUser(invite_code) {
    const response = await fetch(`${process.env.REACT_APP_BASE_URL}/getUserByInviteCode/${invite_code}`, {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + localStorage.getItem('token'),
      },
    });

    const data = await response.json();

    if (response.ok) {
      setFirstName(data.first_name)
      setLastName(data.last_name)
      setName(data.first_name + ' ' + data.last_name)
      setEmail(data.email)
      setUserType(data.user_type)
      setPhone(data.phone)
      setCreatedBy(data.created_by)
      setDidMount(true)
    } else {
      navigate('/login')
    }
  }

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (password !== confirmPassword) {
      alert('Password does not match. Please check and try again.');
      setPassword('');
      setConfirmPassword('');
    }

    try {
      const invite_code = searchParams.get("invite_code");

      const params = {
        email,
        password,
        name: name,
        given_name: firstName,
        family_name: lastName,
        connection: "Username-Password-Authentication",
        user_metadata: {},
      };

      if (userType) {
        params.user_metadata.user_type = userType;
      }

      if (invite_code)  {
        params.user_metadata.register_data = { code: invite_code, name, first_name: firstName, last_name: lastName, title, company, city, state, phone, created_by: createdBy }
      } else {
        alert("Invalid invite code. Please try again.")
        return
      }

      const response = await fetch(`https://${process.env.REACT_APP_AUTH0_DOMAIN}/api/v2/users`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + localStorage.getItem('token'),
        },
        body: JSON.stringify(params),
      });

      const data = await response.json();

      if (response.ok) {
        localStorage.clear();
        alert("Registered successfully."); // Notify success
        setName('');
        setEmail('');
        setTitle('');
        setCompany('');
        setCity('');
        setState('');
        setPassword('');
        navigate('/login')
      } else {
        alert(`Error adding ${userType}: ` + data.message); // Notify error
      }
    } catch (error) {
      alert(`Error adding ${userType}: ` + error.message); // Notify error
    }
  };

  function toTitleCase(str) {
    return str.replace(/_/g, " ").replace(
      /\w\S*/g,
      text => text.charAt(0).toUpperCase() + text.substring(1).toLowerCase()
    );
  }

  return !didMount ? (<div></div>) : (
    <div className="modal-overlay">
      <div className="modal" style={{display: 'inline-block' }}>
        <h1>Register as {toTitleCase(userType)} </h1>
        <form onSubmit={handleSubmit}>
          <div className="input-container">
            <label htmlFor="email">Email:</label>
            <input
              type="email"
              id="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              disabled
              required
            />
          </div>
          <div className="input-container">
            <label htmlFor="firstName">First Name:</label>
            <input
              type="text"
              id="firstName"
              value={firstName}
              onChange={(e) => setFirstName(e.target.value)}
              disabled
              required
            />
          </div>
          <div className="input-container">
            <label htmlFor="lastName">Last Name:</label>
            <input
              type="text"
              id="lastName"
              value={lastName}
              onChange={(e) => setLastName(e.target.value)}
              disabled
              required
            />
          </div>
          {/* <div className="input-container">
            <label htmlFor="title">Title:</label>
            <input
              type="text"
              id="title"
              value={title}
              onChange={(e) => setTitle(e.target.value)}
              required
            />
          </div>
          <div className="input-container">
            <label htmlFor="company">Company:</label>
            <input
              type="text"
              id="company"
              value={company}
              onChange={(e) => setCompany(e.target.value)}
              required
            />
          </div>
          <div className="input-container">
            <label htmlFor="city">City:</label>
            <input
              type="text"
              id="city"
              value={city}
              onChange={(e) => setCity(e.target.value)}
              required
            />
          </div>
          <div className="input-container">
            <label htmlFor="state">State:</label>
            <input
              type="text"
              id="state"
              value={state}
              onChange={(e) => setState(e.target.value)}
              required
            />
          </div> */}
          <div className="input-container">
            <label htmlFor="state">Phone:</label>
            <input
              type="text"
              id="phone"
              value={phone}
              onChange={(e) => setPhone(e.target.value)}
              required
            />
          </div>
          <div className="input-container">
            <label htmlFor="password">Password:</label>
            <input
              type="password"
              id="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              required
            />
          </div>
          <div className="input-container">
            <label htmlFor="confirmPassword">Confirm Password:</label>
            <input
              type="password"
              id="confirmPassword"
              value={confirmPassword}
              onChange={(e) => setConfirmPassword(e.target.value)}
              required
            />
          </div>
          <button type="submit" className="addStepButton">Register</button>
        </form>
        {message && <p>{message}</p>}
      </div>
    </div>
  );
};

export default RegisterUserPage;
