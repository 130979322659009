import React, { useState, useContext } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { useNavigate } from "react-router-dom";
import { useCaregiver } from "./CaregiverContext";
import { ClientContext } from "./ClientContext";
import "./Login.css";
import CompassIcon from "../src/assets/CompassIcon.png";


require("dotenv").config();
const BASE_URL = process.env.REACT_APP_BASE_URL
function Login({ onLogin }) {
  const { user, isAuthenticated, isLoading, loginWithRedirect, logout } = useAuth0();

  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [loginError, setLoginError] = useState("");
  const {
    selectedClient,
    setSelectedClient,
    handleClientChange,
    clients,
    setClients,
  } = useContext(ClientContext);
  const { setCaregiverId, setIsCaregiver } = useCaregiver();
  const navigate = useNavigate();

  function Login({ onLogin }) {
    const { user, isAuthenticated, isLoading, loginWithRedirect, logout } = useAuth0();
  
    const [username, setUsername] = useState("");
    const [password, setPassword] = useState("");
    const [loginError, setLoginError] = useState("");
    const {
      selectedClient,
      setSelectedClient,
      handleClientChange,
      clients,
      setClients,
    } = useContext(ClientContext);
  }
    async function handleSubmit(event) {
      event.preventDefault();
      
      localStorage.clear();
      setLoginError(""); // Reset login error message
      setSelectedClient("");
      localStorage.removeItem("selectedClient");
  
      onLogin(true);
  
      loginWithRedirect({
        redirect_uri: window.location.origin
      })
    }

  return isLoading ? (null) : (
    <div className="App-content">
      <div className="loginContainerUnique">
        <div className="loginLogoUnique">
          <img src={CompassIcon} alt="Logo" />
          <h1>Welcome to Compass Care</h1>
          <p>Enter your info to get started.</p>
        </div>
        <div>
          <form onSubmit={handleSubmit}>
            <button type="submit" className="btn-branding loginButtonUnique">
              Sign in
            </button>
          </form>
        </div>
      </div>
    </div>
  );
}

export default Login;
