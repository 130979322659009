import React, { useState, useEffect, useContext } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { useNavigate, useLocation } from "react-router-dom";
import Select from 'react-select';
import "./Layout.css";
import { useCaregiver } from "./CaregiverContext";
import { ClientContext } from "./ClientContext";
import NotificationAlert from "./NotificationAlert";

require("dotenv").config();
const BASE_URL = process.env.REACT_APP_BASE_URL;

const Layout = ({ children }) => {
  const { user, isAuthenticated, isLoading, logout } = useAuth0();
  // const [clients, setClients] = useContext(ClientContext)
  const {
    selectedClient,
    setSelectedClient,
    handleClientChange,
    clients,
    setClients,
    fetchClients,
    fetchClientName,
    fetchActivities,
    activities,
    setActivities
  } = useContext(ClientContext)
  const [isDropdownVisible, setIsDropdownVisible] = useState(false);
  // const { contacts, setContacts } = useContext(ClientContext);
  const [caregivers, setCaregivers] = useState([]);
  const [selectedCaregiver, setSelectedCaregiver] = useState(
    localStorage.getItem("selectedCaregiver") || "",
  );
  const { caregiverId, isCaregiver } = useCaregiver();
  const navigate = useNavigate();
  const location = useLocation();
  const [clientOptions, setClientOptions] = useState([]);
  const [caregiverOptions, setCaregiverOptions] = useState([]);

  const toggleDropdown = () => {
    setIsDropdownVisible(!isDropdownVisible);
  };

  const onInputChangeClient = (
    newValue, action
  ) => {
    const clientId = newValue.value;

    if (clientId == "create") {
      navigate('/add-client');
      return;
    }
    setSelectedClient(clientId);
    localStorage.setItem("selectedClient", clientId);

    if (localStorage.getItem("isSuperAdmin") == "true") {
      const user = clients.find((user) => user.id === parseInt(clientId));
      navigate(`/client_profile/${user.id}`, { state: { user: user } });
    }
  };

  const onInputChangeCaregiver = (
    newValue, action
  ) => {
    const caregiverId = newValue.value;

    if (caregiverId == "create") {
      navigate(localStorage.getItem('isSuperAdmin') ? '/add-admin' : '/add-caregiver');
      return;
    }
    setSelectedCaregiver(caregiverId);
    localStorage.setItem("selectedCaregiver", caregiverId);

    if (localStorage.getItem("isSuperAdmin") == "true" || localStorage.getItem("isAdmin") == "true") {
      const user = caregivers.find((user) => user.id === parseInt(caregiverId));
      navigate(`/caregiver_profile/${user.id}`, { state: { user: user } });
    }
  };

  useEffect(() => {
    const fetchActivities = async () => {
      try {
        const response = await fetch(`${BASE_URL}/activities`, {
          headers: {
            'Authorization': 'Bearer ' + localStorage.getItem('token'),
          }
        });
        if (response.ok) {
          const data = await response.json();
          setActivities(data);
        } else {
          console.error("Failed to fetch activities");
        }
      } catch (error) {
        console.error("Error fetching activities:", error);
      }
    };
    fetchActivities();
  }, [selectedClient]);

  useEffect(() => {
    if (
      !location.pathname.includes("select-client-homepage") &&
      location.pathname.includes("/calendar") ||
      location.pathname.includes("/track-activities") ||
      location.pathname.includes("/contacts") ||
      location.pathname.includes("/call")
    ) {
      if (location.pathname.includes("/calendar")) {
        navigate(`/calendar/${selectedClient}`, {
          state: { isCaregiver: true },
        });
      } else if (location.pathname.includes("/track-activities")) {
        navigate(`/track-activities/${selectedClient}`, {
          state: { isCaregiver: true },
        });
      } else if (location.pathname.includes("/contacts")) {
        navigate(`/contacts/${selectedClient}`, {
          state: { isCaregiver: true },
        });
      } else if (location.pathname.includes("/call")) {
        navigate(`/call/${selectedClient}`, {
          state: { isCaregiver: true },
        });
      }

      if (clients.length > 0) {
        let options = [];
        if (localStorage.getItem("isSuperAdmin") == "true" || localStorage.getItem('isAdmin')) {
          options.push({ value: 'create', label: '--- Add new client ---' });
        }
        clients.forEach((c) => { options.push(c) });
        setClientOptions(options)
      }
    }
  }, [selectedClient, location.pathname]);

  // const fetchActivities = async (clientId) => {
  //   try {
  //     const response = await fetch(
  //       `${BASE_URL}/clients/${clientId}/activities`, {
  //         headers: {
  //           'Authorization': 'Bearer ' + localStorage.getItem('token'),
  //         }
  //     });
  //     if (response.ok) {
  //       const data = await response.json();
  //       navigate(`/calendar/${clientId}`, {
  //         state: { isCaregiver: true, activities: data },
  //       });
  //     } else {
  //       console.error("Failed to fetch activities for client");
  //     }
  //   } catch (error) {
  //     console.error("Error fetching activities for client:", error);
  //   }
  // };

  useEffect(() => {
    const fetchCaregivers = async () => {
      let url = `${BASE_URL}/caregivers?user_id=${localStorage.getItem("caregiverId")}`;
      if (localStorage.getItem('isSuperAdmin')) {
        url = `${BASE_URL}/caregivers`
      }
      try {
        const response = await fetch(
          url, {
          headers: {
            'Authorization': 'Bearer ' + localStorage.getItem('token'),
          }
        });
        if (response.ok) {
          const data = await response.json();
          setCaregivers(data);
          let options = [];
          if (localStorage.getItem("isSuperAdmin") == "true" || localStorage.getItem('isAdmin')) {
            options.push({ value: 'create', label: '--- Add new client ---' });
          }
          data.forEach((c) => { options.push(c) });
          setCaregiverOptions(options)
        } else {
          console.error("Failed to fetch caregivers");
        }
      } catch (error) {
        console.error("Error fetching caregivers", error);
      }
    };

    if (localStorage.getItem('isSuperAdmin') == 'true' || localStorage.getItem('isAdmin') == 'true') {
      fetchCaregivers();
    }
  }, [caregiverId, setCaregivers]);

  const handleHomepageClick = () => {
    setSelectedClient("");
    localStorage.removeItem("selectedClient");
    navigate("../select-client-homepage", {
      state: { caregiverId: localStorage.getItem("caregiverId") },
    });
  };

  const handleCaregiverChange = (e) => {
    const caregiverId = e.target.value;
    if (caregiverId == 'create') {
      navigate(localStorage.getItem('isSuperAdmin') ? '/add-admin' : '/add-caregiver');
      return
    }
    setSelectedCaregiver(caregiverId);
    localStorage.setItem("selectedCaregiver", caregiverId);
    if (localStorage.getItem('isAdmin') == 'true') {
      const user = caregivers.find(user => user.id === parseInt(caregiverId))
      navigate(`/caregiver_profile/${user.id}`, { state: { user: user } });
    }
  };

  const handleClientScheduleClick = () => {
    if (selectedClient) {
      navigate(`/calendar/${selectedClient}`, { state: { isCaregiver: true } });
    }
  };

  const handleTrackActivitiesClick = () => {
    if (selectedClient) {
      navigate(`/track-activities/${selectedClient}`, {
        state: { isCaregiver: true },
      });
    }
  };

  const handleCallListsCLick = () => {
    if (selectedClient) {
      navigate(`/contacts/${selectedClient}`, { state: { isCaregiver: true } });
    }
  };

  const handleCallClick = () => {
    if (selectedClient) {
      navigate(`/call/${selectedClient}`, { state: { isCaregiver: true } });
    }
  }

  const handleProfileInfoClick = () => {
    const user = clients.find(user => user.id === parseInt(selectedClient))
    navigate(`/client_profile/${user.id}`, { state: { user: user } })
  }

  return (
    <div className="outer-container">
      <header className="header">
        <button className="logo-button" onClick={handleHomepageClick}>
          <img src="/images/Compass-Care_White.png" alt="Logo" style={{ marginLeft: '10px', width: '200px' }} />
        </button>
        <div className="sch-client-selector" style={{ paddingRight: '20px' }}>
          <Select
            defaultValue={1}
            name="clients"
            onChange={onInputChangeClient}
            options={clientOptions}
            value={selectedClient}
            className="basic-single-select"
            classNamePrefix="select"
            placeholder="Search Clients"
          />
        </div>
        <div style={{ width: '25%', marginRight: 'auto' }}>
          <Select
            defaultValue={1}
            name="caregivers"
            onChange={onInputChangeCaregiver}
            options={caregiverOptions}
            value={selectedCaregiver}
            className="basic-single-select"
            classNamePrefix="select"
            placeholder="Search Caregivers"

          />
        </div>
        <button className="btn-branding" style={{ width: "150px"}} onClick={(ev) => {
          ev.preventDefault();
          logout({ logoutParams: { returnTo: window.location.origin + '/login' } });
          localStorage.clear();
        }}>
          Log Out
        </button>
        <div className="notification-icon" onClick={toggleDropdown}>
          <NotificationAlert
            activities={activities}
            isDropdownVisible={isDropdownVisible}
          />
        </div>
      </header>
      <div className="main-content-container">
        <div className="left-nav">
          <ul className="sidebar-nav">
            <li onClick={handleClientScheduleClick}>Schedule</li>
            <li onClick={handleCallListsCLick}>Call lists</li>
            <li onClick={handleTrackActivitiesClick}>Track activities</li>
            <li onClick={handleCallClick}>Video Call</li>
            {localStorage.getItem('isSuperAdmin') == 'true' ?
              (<li onClick={handleProfileInfoClick}>Profile Info</li>) : (null)
            }
          </ul>
        </div>
        <div className="main-content">{children}</div>
      </div>
    </div>
  );
};

export default Layout;
