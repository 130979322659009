// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* App.css */
.App {
  text-align: center;
}

.App-content {
  background-color: white;
  min-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: calc(10px + 2vmin);
  color: black;
}

.App h1 {
  font-size: auto;
  color: black;
}

form input {
  margin: 10px 0;
  padding: 10px;
}

form textarea {
  margin: 10px 0;
  padding: 10px;
}

form select {
  margin: 10px 0;
  padding: 10px;
}

form button {
  padding: 15px 30px;
  font-size: 1.2em;
  cursor: pointer;
  margin-top: 20px;
  width: auto;
  height: auto;
  border: none;
  background-color: blue;
  color: white;
  border-radius: 5px;
}

.contacts-page {
  font-family: Arial, sans-serif;
  color: white;
  background-color: black;
  height: 100vh;
  display: flex;
  flex-direction: column;
}

.header {
  display: flex;
  align-items: center;
  padding: 10px;
  background-color: #333;
}
`, "",{"version":3,"sources":["webpack://./src/App.css"],"names":[],"mappings":"AAAA,YAAY;AACZ;EACE,kBAAkB;AACpB;;AAEA;EACE,uBAAuB;EACvB,iBAAiB;EACjB,aAAa;EACb,uBAAuB;EACvB,mBAAmB;EACnB,6BAA6B;EAC7B,YAAY;AACd;;AAEA;EACE,eAAe;EACf,YAAY;AACd;;AAEA;EACE,cAAc;EACd,aAAa;AACf;;AAEA;EACE,cAAc;EACd,aAAa;AACf;;AAEA;EACE,cAAc;EACd,aAAa;AACf;;AAEA;EACE,kBAAkB;EAClB,gBAAgB;EAChB,eAAe;EACf,gBAAgB;EAChB,WAAW;EACX,YAAY;EACZ,YAAY;EACZ,sBAAsB;EACtB,YAAY;EACZ,kBAAkB;AACpB;;AAEA;EACE,8BAA8B;EAC9B,YAAY;EACZ,uBAAuB;EACvB,aAAa;EACb,aAAa;EACb,sBAAsB;AACxB;;AAEA;EACE,aAAa;EACb,mBAAmB;EACnB,aAAa;EACb,sBAAsB;AACxB","sourcesContent":["/* App.css */\n.App {\n  text-align: center;\n}\n\n.App-content {\n  background-color: white;\n  min-height: 100vh;\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  font-size: calc(10px + 2vmin);\n  color: black;\n}\n\n.App h1 {\n  font-size: auto;\n  color: black;\n}\n\nform input {\n  margin: 10px 0;\n  padding: 10px;\n}\n\nform textarea {\n  margin: 10px 0;\n  padding: 10px;\n}\n\nform select {\n  margin: 10px 0;\n  padding: 10px;\n}\n\nform button {\n  padding: 15px 30px;\n  font-size: 1.2em;\n  cursor: pointer;\n  margin-top: 20px;\n  width: auto;\n  height: auto;\n  border: none;\n  background-color: blue;\n  color: white;\n  border-radius: 5px;\n}\n\n.contacts-page {\n  font-family: Arial, sans-serif;\n  color: white;\n  background-color: black;\n  height: 100vh;\n  display: flex;\n  flex-direction: column;\n}\n\n.header {\n  display: flex;\n  align-items: center;\n  padding: 10px;\n  background-color: #333;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
